@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply tracking-normal h-screen bg-base-100 text-base-content;
}

/* Top Tooltips */
.top-tooltips {
    @apply absolute bg-base-content text-base-100 px-2 py-1 text-sm left-1/2 transform -translate-x-1/2 rounded-md hidden shadow z-20 min-w-fit whitespace-nowrap;
}

.top-tooltips::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    border-width: 5px;
    border-style: solid;
    border-color: #191A25 transparent transparent transparent;
    transform: translateX(-50%);
}

.dark .top-tooltips::after {
    border-color: #FFFFFF transparent transparent transparent;
}

/* Bottom Tooltips */
.bottom-tooltips {
    @apply absolute bg-base-content text-base-100 px-2 py-1 text-sm left-1/2 transform -translate-x-1/2 rounded-md hidden shadow z-20 min-w-fit whitespace-nowrap;
}

.bottom-tooltips::before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #191A25 transparent;
    transform: translateX(-50%);
}

.dark .bottom-tooltips::before {
    border-color: transparent transparent #FFFFFF transparent;
}

/* Right Tooltips */
.right-tooltips {
    @apply absolute bg-base-content text-base-100 px-2 py-1 text-sm bottom-1/2 transform translate-y-1/2 rounded-md hidden shadow z-40 min-w-fit whitespace-nowrap;
}

.right-tooltips::before {
    content: "";
    position: absolute;
    right: 100%;
    bottom: 50%;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #191A25 transparent transparent;
    transform: translateY(50%);
}

.dark .right-tooltips::before {
    border-color: transparent #FFFFFF transparent transparent;
}

/* Left Tooltips */
.left-tooltips {
    @apply absolute bg-base-content text-base-100 px-2 py-1 text-sm bottom-1/2 transform translate-y-1/2 rounded-md hidden shadow z-20 min-w-fit whitespace-nowrap;
}

.left-tooltips::after {
    content: "";
    position: absolute;
    left: 100%;
    bottom: 50%;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #191A25;
    transform: translateY(50%);
}

.dark .left-tooltips::after {
    border-color: transparent transparent transparent #FFFFFF;
}

@layer components {
    .scrollbar::-webkit-scrollbar {
        @apply w-2 block;
    }

    .scrollbar::-webkit-scrollbar-track {
        @apply bg-transparent block;
    }

    .scrollbar::-webkit-scrollbar-thumb {
        @apply bg-base-content/10 rounded-full block;
    }

    .scrollbar::-webkit-scrollbar-thumb:hover {
        @apply bg-base-content/20 rounded-full block cursor-default;
    }
}

/* Style for auto-hiding scrollbar */
.auto-hide-scrollbar::-webkit-scrollbar {
    width: 9px;
    @apply opacity-0 hover:opacity-100;
}

.auto-hide-scrollbar::-webkit-scrollbar-track {
    @apply bg-transparent;
}

.auto-hide-scrollbar::-webkit-scrollbar-thumb {
    @apply bg-transparent rounded-full;
}

.auto-hide-scrollbar:hover::-webkit-scrollbar-thumb {
    @apply bg-base-content/10 rounded-full opacity-100;
}

.auto-hide-scrollbar::-webkit-scrollbar-thumb:hover {
    @apply bg-base-content/20 cursor-default;
}

.modal {
    @apply fixed w-screen h-screen;
}

.button-ring-focus {
    @apply focus-visible:ring focus-visible:ring-info/40 focus-visible:outline-none dark:focus-visible:ring-info/40 focus-visible:rounded-md;
}

.input-ring-focus {
    @apply focus-visible:ring focus-visible:outline-none focus-visible:ring-info/30 dark:focus-visible:ring-info/30 focus-visible:rounded-md focus-visible:ring-offset-1 focus-visible:ring-offset-info/10 focus:ring-0;
}

.dropdown-item {
    @apply flex flex-row w-full items-center space-x-2 py-0.5 px-4 text-sm leading-6 text-base-content hover:sm:bg-base-200 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:sm:bg-base-100 focus-visible:outline-none focus-visible:bg-neutral cursor-pointer enabled:hover:sm:text-primary z-50;
}

.checkbox-ring-focus {
    @apply focus:ring focus:ring-info/40 dark:focus:ring-info/40 focus:ring-offset-base-100 focus:ring-offset-2;
}

.standard-checkbox {
    @apply h-4 w-4 rounded bg-base-100 text-primary outline-none shadow shadow-gray-400/50 dark:shadow-black/50 ring-[0.5px] ring-base-content/30 dark:ring-base-content/40 border-0 checkbox-ring-focus disabled:pointer-events-none disabled:opacity-75;
}

.neutral-button {
    @apply relative flex items-center justify-center bg-base-300 text-white ring-base-300 ring-[0.75px] rounded-md px-2 py-1 hover:drop-shadow-sm shadow-sm shadow-gray-400/70 dark:shadow-black/30 outline-none text-sm min-w-max button-ring-focus button-ring-focus disabled:pointer-events-none;
}

.primary-button {
    @apply relative flex items-center justify-center text-primary-content bg-primary ring-primary ring-[0.75px] rounded-md px-2 py-1 hover:drop-shadow-sm shadow-sm shadow-gray-400/50 dark:shadow-black/50 outline-none text-sm min-w-max button-ring-focus disabled:pointer-events-none;
}

.secondary-button {
    @apply relative flex items-center justify-center text-secondary-content bg-secondary ring-secondary ring-[0.75px] rounded-md px-2 py-1 hover:drop-shadow-sm shadow-sm shadow-gray-400/50 dark:shadow-black/50 outline-none text-sm min-w-max button-ring-focus disabled:pointer-events-none;
}

.accent-button {
    @apply relative flex items-center justify-center text-accent-content bg-accent ring-accent ring-[0.75px] rounded-md px-2 py-1 hover:drop-shadow-sm shadow-sm shadow-gray-400/50 dark:shadow-black/50 outline-none text-sm min-w-max button-ring-focus disabled:pointer-events-none;
}

.success-button {
    @apply relative flex items-center justify-center bg-success text-success-content ring-success ring-[0.75px] rounded-md px-2 py-1 hover:drop-shadow-sm shadow-sm shadow-gray-400/50 dark:shadow-black/50 outline-none text-sm min-w-max button-ring-focus disabled:pointer-events-none;
}

.error-button {
    @apply relative flex items-center justify-center bg-error-content text-error ring-error-content ring-[0.75px] rounded-md px-2 py-1 hover:drop-shadow-sm shadow-sm shadow-gray-400/50 dark:shadow-black/50 outline-none text-sm min-w-max button-ring-focus disabled:pointer-events-none;
}

.empty-button {
    @apply w-auto text-sm font-medium text-base-content/90 hover:text-base-content outline-none px-2 disabled:pointer-events-none;
}

.standard-button {
    @apply relative flex items-center justify-center bg-base-100 dark:bg-base-200 text-base-content ring-[0.75px] ring-base-content/20 dark:ring-base-content/20 rounded-md px-2 py-1 hover:drop-shadow-sm shadow-sm shadow-gray-400/50 dark:shadow-black/50 outline-none text-sm min-w-max button-ring-focus disabled:pointer-events-none;
}

.success-badge {
    @apply capitalize text-success-content bg-success/30 ring-success-content/20 rounded whitespace-nowrap px-1.5 py-0.5 text-xs ring-1 ring-inset w-fit h-fit flex items-center;
}

.error-badge {
    @apply capitalize text-rose-700 dark:text-rose-500 bg-rose-400/20 dark:bg-rose-900/20 ring-rose-700/20 dark:ring-rose-500/20 rounded whitespace-nowrap px-1.5 py-0.5 text-xs ring-1 ring-inset w-fit h-fit flex items-center;
}

.secondary-badge {
    @apply capitalize text-secondary-focus dark:text-secondary-focus-dark bg-secondary/20 ring-secondary/20 rounded whitespace-nowrap px-1.5 py-0.5 text-xs ring-1 ring-inset w-fit h-fit flex items-center;
}

.neutral-badge {
    @apply capitalize text-base-content/70 bg-neutral-focus/50 ring-neutral-focus/70 dark:bg-neutral-focus-dark/50 dark:ring-neutral-focus-dark/70 rounded whitespace-nowrap px-1.5 py-0.5 text-xs ring-1 ring-inset w-fit h-fit flex items-center;
}

.warning-badge {
    @apply capitalize text-warning-content bg-warning/30 ring-warning-content/20 rounded whitespace-nowrap px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset w-fit h-fit flex items-center;
}

.modal-wrapper {
    @apply modal-box self-end sm:self-center justify-center sm:items-center p-5 my-4 rounded-md scrollbar;
}

.modal-bg {
    @apply py-4 px-0 bg-black/50 backdrop-blur-sm;
}

.dialog-wrapper {
    @apply modal-box self-end sm:self-center justify-center sm:items-center p-5 rounded-md overflow-y-auto scrollbar max-w-sm flex flex-col items-center gap-y-3;
}

.input-field {
    @apply block w-full appearance-none rounded-md border-0 bg-base-100 shadow-sm shadow-gray-400/30 dark:shadow-black/50 ring-[0.75px] ring-base-content/20 outline-none text-base-content text-sm sm:leading-6 focus:placeholder-transparent placeholder:text-base-content/30 placeholder:font-light disabled:pointer-events-none disabled:text-base-content/70 py-1 input-ring-focus font-light justify-center;
}

.empty-input-field {
    @apply block w-full appearance-none rounded-md border-0 bg-base-100 outline-none text-base-content text-sm sm:leading-6 focus:placeholder-transparent placeholder:text-base-content/30 placeholder:font-light disabled:pointer-events-none disabled:text-base-content/70 py-1 focus-visible:ring-0 focus-visible:outline-none focus-visible:rounded-md focus:ring-0 font-light justify-center;
}

.toggle-button {
    @apply relative flex items-center h-4 w-8 flex-shrink-0 rounded-full border-2 border-transparent duration-200 ease-in-out focus:outline-none ring-1 ring-base-300/70 focus:ring-2 focus:ring-primary focus-visible:ring focus-visible:ring-info/40 focus:ring-offset-2 focus:ring-offset-base-200 disabled:cursor-not-allowed backdrop-blur-sm;
}

.input-built-in-title {
    @apply absolute -top-2 left-2 group-focus-within:inline-block bg-base-100 group-focus-within:text-info/70 px-0.5 text-xs font-medium hidden leading-none z-10;
}

.select-dropdown {
    @apply absolute mt-1 max-h-48 w-full overflow-y-auto scrollbar rounded-md py-1 focus:outline-none text-sm z-20 bg-base-100 shadow-[0px_0px_15px_rgba(0,0,0,0.15)] dark:shadow-[0px_0px_15px_rgba(0,0,0,0.85)] ring-[0.5px] ring-neutral-focus/70 dark:ring-neutral-focus-dark/70;
}

.button-dropdown {
    @apply absolute right-0 rounded-md bg-base-100 text-base-content shadow-[0px_0px_15px_rgba(0,0,0,0.15)] dark:shadow-[0px_0px_15px_rgba(0,0,0,0.85)] ring-[0.5px] ring-neutral-focus/70 dark:ring-neutral-focus-dark/70 focus:outline-none overflow-hidden py-1 dropdown-content z-20;
}

.spread-shadow {
    @apply shadow-sm shadow-gray-400/50 dark:shadow-black/50;
}

.success-alert-colors {
    @apply bg-emerald-200/50 text-emerald-700 dark:bg-emerald-700/50 dark:text-emerald-100 backdrop-blur ring-1 ring-emerald-100/70 dark:ring-emerald-800/70;
}

.error-alert-colors {
    @apply bg-rose-200/50 text-rose-700 dark:bg-rose-700/50 dark:text-rose-100 backdrop-blur ring-1 ring-rose-100/70 dark:ring-rose-800/70;
}

.warning-alert-colors {
    @apply bg-amber-200/50 text-amber-700 dark:bg-amber-700/50 dark:text-amber-100 backdrop-blur ring-1 ring-amber-100/70 dark:ring-amber-800/70;
}

.info-alert-colors {
    @apply bg-blue-200/50 text-blue-700 dark:bg-blue-700/50 dark:text-blue-100 backdrop-blur ring-1 ring-blue-100/70 dark:ring-blue-800/70;
}

.rounded-primary-button {
    @apply relative flex items-center text-primary-content bg-primary ring-primary ring-[0.75px] rounded-full pl-3 pr-1.5 py-1 hover:drop-shadow-sm shadow-sm shadow-gray-400/50 dark:shadow-black/50 outline-none text-sm min-w-max button-ring-focus disabled:opacity-50 disabled:pointer-events-none space-x-0.5 font-medium;
}

.rounded-empty-button {
    @apply relative flex items-center text-base-content rounded-full py-1 outline-none text-sm min-w-max button-ring-focus disabled:opacity-50 disabled:pointer-events-none space-x-0.5 font-medium w-fit;
}
