@tailwind components;
@tailwind utilities;


.prose :where(p):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
    @apply my-0;
}

.prose :where(.prose > :first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
    @apply my-0;
}